@import "../../../assets/vars";
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url("../../../assets/images/cover.jpg");
  background-size: cover;

  &-form {
    border-radius: 20px;
    width: 70vw;
    max-width: 450px;
    text-align: center;
    padding: 20px 40px;
    background-color: $white;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .logo {
      img {
        width: 150px;
      }
    }
    .input-wrapper {
      h5 {
        margin: 10px 0;
        font-size: 15px;
        text-align: left;
      }
    }
    .phrase {
      margin: 20px 0;
      font-size: 20px;
      color: $primary-pink;
    }
  }
}
@include desktop {
  .login {
    &-form {
      width: 90vw;
      justify-content: space-evenly;
      background-color: $white;
    }
  }
}
