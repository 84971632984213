.p-dropdown {
  box-shadow: unset;
  width: 100%;
  padding: 0px;
  max-height: 50px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: 0.5s ease-in-out;
  &:hover,
  &:focus {
    outline: 2px solid $primary-pink;
    outline-offset: -2px;
    transition: 0.5s ease-in-out;
    border-color: $primary-pink;
  }
}
