@import "../../assets/vars";
.orders-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px;
  font-family: "Noto Sans Arabic", sans-serif;

  .check-btn {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: $white;
    border-radius: 10px;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .p-inputswitch-slider {
      box-shadow: unset;
      background-color: $secondary-pink;
    }
    .p-inputswitch-checked {
      .p-inputswitch-slider {
        background-color: $primary-pink;
      }
    }
    .p-inputswitch.p-highlight .p-inputswitch-slider:before {
      background-color: $primary-pink;
    }
  }
  .load-more {
    text-align: center;
  }
}
