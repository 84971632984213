@mixin mobile {
  @media (max-width: 480px) {
    @content;
  }
}
@mixin tablet {
  @media (max-width: 768px) {
    @content;
  }
}
@mixin desktop {
  @media (max-width: 960px) {
    @content;
  }
}
@mixin screen {
  @media (max-width: 1200px) {
    @content;
  }
}
