@import "../../assets/vars";

.change-password {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
  .title {
    width: 50%;
    text-align: center;
    background-color: $primary-pink;
    color: $white;
    padding: 10px;
    border-radius: 20px;
    margin: 20px;
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding: 20px;
    border-radius: 20px;
    gap: 20px;

    .input-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      span {
        color: $primary-pink;
      }
    }
  }
  .action {
    text-align: center;
  }
}
