@import "../../assets/vars";
.order-card {
  background-color: $white;
  border-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  .order-details {
    @include desktop {
      flex-direction: column;
    }
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    .left-section {
      padding: 10px;
      flex: 10;
      direction: rtl;
    }
    .user-details {
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      strong {
        text-align: center;
      }
      .desc {
        text-align: center;
      }
    }
    .product-details {
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
    .product-image {
      flex: 1;
      @include desktop {
        text-align: center;
        img {
          border-radius: 20px;
        }
      }
      img {
        padding: 20px;
        border-top-left-radius: 20px;
        width: 200px;
        height: 200px;
        object-fit: contain;
      }
    }
    span {
      display: flex;
      align-items: center;
      gap: 10px;
      svg {
        padding: 6px;
        background-color: $primary-pink;
        color: $white;
        height: 35px;
        width: 35px;
        border-radius: 5px;
      }
    }
    .divider {
      margin: 0 50px;
      border: 0;
      height: 2px;
      background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0),
        $primary-pink,
        rgba(0, 0, 0, 0)
      );
    }
  }
  .button-container {
    text-align: center;
    margin-bottom: 10px;
  }
}
