@import "./utils/breakpoints.scss";

$primary-color: #3d86f3;
$secondary-color: #6c757d;
$danger-color: #dc3545;
$white-faded: #f5f5f5;

$logo-font: #1d1d1b;

$white: #ffffff;
$black: #000;
$primary-pink: #e54a97;
$secondary-pink: #ea9eb8;
$success-color: #5cb85c;
