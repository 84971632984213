@import "../../../assets/vars";
.about-us {
  padding: 20px;

  .cover-section {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    .bio {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      @include desktop {
        display: block;
        .items {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 5px;
          .item {
            flex-grow: 1;
          }
        }
      }
      p {
        text-align: center;
        flex: 3;
      }
      .items {
        text-align: center;
        flex: 1;
        .item {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 10px;
          background-color: $success-color;
          color: $white;
          padding: 10px 5px;
          margin: 5px 0;
          border-radius: 20px;
        }
      }
    }
    .cover-image {
      width: 100%;
      text-align: center;
      img {
        box-shadow: 0px 0px 10px $primary-pink;
        border: 1px solid $primary-pink;
        position: relative;
        border-radius: 50%;
        width: 200px;
        height: 200px;
      }
    }
    p {
      flex-grow: 1;
      text-align: right;
      padding: 10px;
    }
  }
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
    .title {
      display: flex;
      align-items: center;
      font-size: 20px;
      color: $success-color;
      display: flex;
      gap: 10px;
    }
  }
  .grid {
    margin-top: 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

@include mobile {
  .about-us {
    font-size: 12px !important;
    .cover-image {
      width: unset !important;
      img {
        width: 150px !important;
        height: 150px !important;
      }
    }
  }
}
