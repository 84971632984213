@import "../../assets/vars";
.about-us-card {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
  margin: 10px;
  background-color: inherit;
  border-radius: 20px;
  width: 80%;
  background-color: rgba(0, 0, 0, 0.053);
  @include tablet {
    flex-direction: column;
  }
  .card-header {
    flex: 4;
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
    color: $primary-pink;
    .card-title {
      font-weight: bold;
    }
  }
  .card-link {
    text-decoration: none;
    color: $logo-font;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    word-break: break-all;
  }
  .p-inputtext {
    border-color: $secondary-color;
  }
  .p-inputtext:enabled:hover {
    border-color: $primary-pink;
  }
  img {
    flex: 0.1;
    width: 100px;
  }
  span {
    color: $black;
  }
}
