@import "./vars";
@import "./utils/button.scss";
@import "./utils/input.scss";
@import "./utils/dialog.scss";
@import "./utils/dropdown.scss";
@import "./utils/inputnumber.scss";

* {
  box-sizing: border-box;
  margin: 0;
}
html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

::selection {
  color: #eee;
  background: #86898d;
}
body {
  background-color: $white-faded;
}

.app {
  display: flex;
  width: 100%;
  .content-container {
    flex: 1;
  }
}
.cs-container {
  border-radius: 20px;
  margin: 20px;
  padding: 20px;
  min-height: 100vh;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  background-color: $white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.arabic-text,
textarea {
  text-align: right;
  direction: rtl;
  font-family: "Noto Sans Arabic", sans-serif;
}
.pinky-transparent {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
@include tablet {
  .app {
    display: flex;
    flex-direction: column;
    .sidebar {
      margin: 20px;
      width: auto;
      min-height: unset;
      ul {
        display: block;
      }
    }
  }
}
