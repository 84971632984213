@import "./../vars";

.datatable {
  box-shadow: 0 0 0 1px $primary-pink;
  margin: 20px;
  border-radius: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    .title {
      flex: 3;
      font-weight: 900;
      padding: 10px;
      font-size: 20px;
      color: $success-color;
    }
    .p-button {
      color: $success-color;
      &.p-button-text:focus {
        box-shadow: inset 0 -3em 3em #eee;
      }
    }
    .p-dropdown {
      flex: 1;
    }
  }
  .p-datatable {
    position: relative;
    overflow-x: auto;
    th {
      min-width: 200px;
      padding: 10px;
    }
    td {
      min-width: 200px;
    }
    img {
      width: 100px;
      object-fit: contain;
      max-height: 50px;
    }
    .p-datatable-header {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
    .p-datatable-tbody > tr {
      .hoverable-cell {
        &:hover {
          word-wrap: unset;
          white-space: initial;
        }
      }
    }
    .p-datatable-tbody > tr > td {
      text-align: center;
      .ar {
        direction: rtl;
      }
      .en {
        direction: ltr;
      }
    }
    .p-column-header-content {
      justify-content: center;
    }
  }
  .p-column-header-content {
    .p-column-title {
      color: $primary-pink;
    }
  }
  .p-datatable .p-sortable-column .p-sortable-column-icon {
    margin-left: 10px;
    color: $primary-pink;
  }
  .p-datatable .p-datatable-tbody > tr {
    word-break: break-word;
  }
  .p-paginator {
    border-radius: 20px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
}
